.App {
  text-align: center;
  line-height: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin: 0;
  padding: 0;
  user-select: auto;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
}
